import { Button, Flex, Heading, Link, NextLinkFromReactRouter } from '@pancakeswap/uikit'
import { useAccount } from 'wagmi'
import ConnectWalletButton from 'components/ConnectWalletButton'
import { useTranslation } from '@pancakeswap/localization'
import Image from 'next/image'
import { ChainId } from '@pancakeswap/sdk'
import { useActiveChainId } from 'hooks/useActiveChainId'
import { styled, keyframes } from 'styled-components'
import hero from '../../../../public/images/home/hero-home.png'
import CompositeImage, { CompositeImageProps } from './CompositeImage'
import { SlideSvgDark, SlideSvgLight } from './SlideSvg'
import starL from '../../../../public/images/home/lunar-bunny/star-l.png'
import starR from '../../../../public/images/home/lunar-bunny/star-r.png'
import starTopR from '../../../../public/images/home/lunar-bunny/star-top-r.png'


const flyingAnim = () => keyframes`
  from {
    transform: translate(0,  0px);
  }
  50% {
    transform: translate(-5px, -5px);
  }
  to {
    transform: translate(0, 0px);
  }
`

const fading = () => keyframes`
  from {
    opacity: 0.9;
  }
  50% {
    opacity: 0.1;
  }
  to {
    opacity: 0.9;
  }
`

const GradientText = styled.div`
  font-size: 28px;
  ${({ theme }) => theme.mediaQueries.sm} {
    font-size: 32px;
  }
  background-clip: text !important;
  // background: linear-gradient(90deg, #3b6bff, #2e96ff 60%, #acb7ff);
  background: linear-gradient(45deg, rgb(109 42 151), rgb(0 138 209) 80%) text;
  background-size: 100%;
  ${({ theme }) => theme.mediaQueries.sm} {
    background-size: 90%
  }
  -webkit-text-fill-color: transparent;
`;


const BgWrapper = styled.div`
  z-index: -1;
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0px;
  left: 0px;
`

const InnerWrapper = styled.div`
  position: absolute;
  width: 100%;
  bottom: -3px;
`

const BunnyWrapper = styled.div`
  width: 50px;
  animation: ${flyingAnim} 3.5s ease-in-out infinite;
  position: relative;
  will-change: transform;
  > span {
    overflow: visible !important; // make sure the next-image pre-build blur image not be cropped
  }
`

const StarsWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  & :nth-child(2) {
    animation: ${fading} 2s ease-in-out infinite;
    animation-delay: 1s;
  }

  & :nth-child(3) {
    animation: ${fading} 5s ease-in-out infinite;
    animation-delay: 0.66s;
  }

  & :nth-child(4) {
    animation: ${fading} 2.5s ease-in-out infinite;
    animation-delay: 0.33s;
  }
`

const starsImage: CompositeImageProps = {
  path: '/images/home/lunar-bunny/',
  attributes: [
    { src: starL, alt: '3D Star' },
    { src: starR, alt: '3D Star' },
    { src: starTopR, alt: '3D Star' },
  ],
}

const Hero = () => {
  const { t } = useTranslation()
  const { address: account } = useAccount()
  const { chainId } = useActiveChainId()

  return (
    <>
      <style jsx global>
        {`
          .slide-svg-dark {
            display: none;
          }
          .slide-svg-light {
            display: block;
          }
          [data-theme='dark'] .slide-svg-dark {
            display: block;
          }
          [data-theme='dark'] .slide-svg-light {
            display: none;
          }
        `}
      </style>
      <BgWrapper>
        <InnerWrapper>
          <SlideSvgDark className="slide-svg-dark" width="100%" />
          <SlideSvgLight className="slide-svg-light" width="100%" />
        </InnerWrapper>
      </BgWrapper>
      <Flex
        position="relative"
        flexDirection={['column', null, null, 'row']}
        alignItems="center"
        justifyContent="center"
        mt={['50px', null, 0]}
        id="homepage-hero"
      >
        <Flex flex="1" flexDirection="column">
          <Heading as="h1" scale="xl" mb="24px">
            <GradientText>
            {t('Welcome to Smart DeFi')}
            </GradientText>
          </Heading>
          <Heading as="p" scale="sm" mb="24px" fontWeight="500 !important">
            {t('Trade, earn, and win crypto on the smart DeFi DEX aggregator.')}
          </Heading>
          <Flex>
            <NextLinkFromReactRouter to="/swap">
              <Button variant={'secondary'}>{t('Trade Now')}</Button>
            </NextLinkFromReactRouter>
          </Flex>
        </Flex>
        
        <Flex
          minHeight={['10px', null, null, '100%']}
          width={['auto', null, null, '100%']}
          flex={[null, null, null, '1']}
          mb={['12px', null, null, '0']}
          position="relative"
          mt={['-15%', null, '-8%', '0']} 
          ml={['75%', null, '65%', '0']}
        >
          <BunnyWrapper>
            <Image
              sizes="(max-width: 768px) 95vw, 580px"
              src={hero}
              priority
              objectFit="fill"
              placeholder="blur"
              alt={t('Obsidian AI')}
            />
          </BunnyWrapper>
          <StarsWrapper>
            <CompositeImage {...starsImage} />
          </StarsWrapper>
        </Flex>
      </Flex>
    </>
  )
}

export default Hero
