import { Text, Link } from '@pancakeswap/uikit'
import { ContextApi } from '@pancakeswap/localization'

type FAQsType = {
  t: ContextApi['t']
}

const config = ({ t }: FAQsType) => [
  /*
  {
    title: t('Are the Swap contracts audited?'),
    description: [
      <Text as="p" color="textSubtle" fontSize="16px">
        {t('Yes, Obsidian has multiple audits including an Audit, Bug Bounty and KYC by CertiK: ',)}
        <Link
          display="inline-flex"
          color="text"
          title="CertiK Audit"
          href="https://skynet.certik.com/projects/icecreamswap"
        >
          {t('CertiK Audit')}
        </Link>
        {t('.')}
      </Text>,
    ],
  },
  */
  {
    title: t('How does Obsidian find the best trading rates?'),
    description: [
      t('Obsidian\'s smart aggregator system is designed to identify the best trading rates by querying multiple ' +
      'decentralized exchanges (DEXs) and selecting the most favorable quote. This process ensures users get the ' +
      'most competitive rates for their trades.'),
    ],
  },
  {
    title: t('What is the Obsidian Smart DEX aggregator?'),
    description: [
      t('Obsidian\'s smart aggregator is a sophisticated system that scans the entire ' +
      'blockchain to find the optimal way to execute your swap. It allows you to trade any token at the best rate by ' +
      'considering the liquidity of Obsidian as well as other DEXes. Simplified, it works like the booking.com of DEXes, ' +
      'finding you the best offer to execute your trade. It even extracts arbitrage opportunities for you, so your trades ' +
      'sometimes end with more value than they began with.'),
    ],
  },
  /*
  {
    title: t('How does Obsidian extract arbitrage for me?'),
    description: [
      t('On every trade, our AI DEX aggregator not only finds you the best way to execute that trade, but even scans for Arbitrage opportunities. ' +
        'These opportunities are extracted directly in your trade. ' +
        'As most Arbitrage is generated from inefficient trades, Obsidian users not only don\'t loose value to Arbitrage, they even directly profit from users that still use less sophisticated DEXes.'),
    ],
  },
*/
  {
    title: t('How to list a token on Obsidian?'),
    description: [
      t('Reach out to us on our Discord server'),
    ],
  },
  /*
  {
    title: t('How much reward will I get for providing liquidity?'),
    description: [
      t(
        'most of the trading fees are distributed directly to our liquidity providers. Also there are Farming pools to receive additional ICE tokens and some tokens like SCORE even generate rewards through securing the Blockchain.',
      ),
    ],
  }
    */
]
export default config
