import { TranslateFunction } from '@pancakeswap/localization'
import { SalesSectionProps } from '.'
import rocket from '../../../../../public/images/home/trade/rocket.png'
import iceConeA from '../../../../../public/images/home/trade/iceconea.png'
import iceConeB from '../../../../../public/images/home/trade/iceconeb.png'
import bridgeA from '../../../../../public/images/home/bridge/bridge_a.png'
import bridgeB from '../../../../../public/images/home/bridge/bridge_b.png'
import earnRobin from '../../../../../public/images/home/earn/robin.png'

export const swapSectionData = (t: TranslateFunction): SalesSectionProps => ({
  headingText: t('Trade any token at optimal rates'),
  bodyText: t(
    'Experience seamless trading across various DEXs with our Smart DEX aggregator, which ensures you get the best rates.  ' +
    'Our aggregator scans the top DEXs on the chain for liquidity pools to optimize your trades, guaranteeing the best possible outcomes.'
  ),
  reverse: false,
  primaryButton: {
    to: '/swap',
    text: t('Trade Now'),
    external: false,
  },
  secondaryButton: {
    to: 'https://wiki.obsidian.finance',
    text: t('Learn'),
    external: true,
  },
  images: {
    path: '/images/home/trade/',
    attributes: [
      { src: rocket, alt: '' },
      //{ src: iceConeB, alt: '' },
    ],
  },
})

export const bridgeSectionData = (t: TranslateFunction): SalesSectionProps => ({
  headingText: t('Bridge securely between many chains'),
  bodyText: t(
    'Obsidian allows direct bridging between many chains with ' +
      'its audited and battle tested smart contracts. ' +
      'The unique built-in faucet automatically drops you native tokens when you bridge to a new chain.'
  ),
  reverse: true,
  primaryButton: {
    to: '/bridge',
    text: t('Bridge Now'),
    external: false,
  },
  secondaryButton: {
    to: 'https://wiki.obsidian.finance/dex/bridge',
    text: t('Learn'),
    external: true,
  },
  images: {
    path: '/images/home/earn/',
    attributes: [
     // { src: bridgeA, alt: '' },
      { src: bridgeB, alt: '' },
    ],
  },
})

export const earnSectionData = (t: TranslateFunction): SalesSectionProps => ({
  headingText: t('Earn tokens while providing liquidity'),
  bodyText: t('Enhance your passive income with liquidity pools.'),
  reverse: false,
  primaryButton: {
    to: '/liquidity',
    text: t('Add Liquidity'),
    external: false,
  },
  /*
  secondaryButton: {
    to: '/',
    text: t('Learn'),
    external: false,
  },
  */
  images: {
    path: '/images/home/robin/',
    attributes: [{ src: earnRobin, alt: '' }],
  },
  secondaryButton: undefined
})
